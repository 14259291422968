import { render, staticRenderFns } from "./EntityPermissionsChip.vue?vue&type=template&id=26d7b094&scoped=true&"
import script from "./EntityPermissionsChip.vue?vue&type=script&lang=ts&"
export * from "./EntityPermissionsChip.vue?vue&type=script&lang=ts&"
import style0 from "./EntityPermissionsChip.vue?vue&type=style&index=0&id=26d7b094&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26d7b094",
  null
  
)

export default component.exports