
import {
  Component, Prop, Vue,
} from "vue-property-decorator";
import { EntityPermission, EntityPermissionAction, TeamRoleEntity } from "@/model/team-role";
import {
  TEAM_ROLES_ENTITY_ACTION_CREATE,
  TEAM_ROLES_ENTITY_ACTION_DELETE,
  TEAM_ROLES_ENTITY_ACTION_READ,
  TEAM_ROLES_ENTITY_ACTION_UPDATE,
  TEAM_ROLES_SPECIAL_ENTITIES,
  TEAM_ROLES_ENTITY_ACTION_NONE,
} from "@/utils/team-role";

@Component({})
export default class EntityPermissionsChip extends Vue {
  @Prop()
  pEntityPermission!: EntityPermission

  get sortedEntityActions() {
    return [...this.pEntityPermission.actions].sort((a, b) => {
      if (a == TEAM_ROLES_ENTITY_ACTION_DELETE) return 1;
      if (b == TEAM_ROLES_ENTITY_ACTION_DELETE) return -1;
      if (a == TEAM_ROLES_ENTITY_ACTION_NONE) return -1;
      if (b == TEAM_ROLES_ENTITY_ACTION_NONE) return 1;

      return a.text.localeCompare(b.text);
    });
  }

  getEntityColor(entity: TeamRoleEntity) {
    if (TEAM_ROLES_SPECIAL_ENTITIES.includes(entity.value)) {
      return ["blue-grey", "lighten-2", "white--text"];
    }

    return "default";
  }

  getActionColor(permission: EntityPermissionAction) {
    switch (permission) {
      case TEAM_ROLES_ENTITY_ACTION_CREATE:
        return ["green", "white--text"];
      case TEAM_ROLES_ENTITY_ACTION_READ:
        return ["cyan", "white--text"];
      case TEAM_ROLES_ENTITY_ACTION_UPDATE:
        return ["orange", "white--text"];
      case TEAM_ROLES_ENTITY_ACTION_DELETE:
        return ["red", "white--text"];
      case TEAM_ROLES_ENTITY_ACTION_NONE:
        return ["black", "white--text"];
      default:
        return "default";
    }
  }
}
